<template>
	<div class="case-details">
		<BannerOther :img_url="img_url" :chinese_name='father_name' :english_name="english_name"></BannerOther>
		<div class="about-main-box">
			<!-- 导航栏 -->
			<div class="crumbs">
				<div class="cru-logo">
					<img src="../../assets/images/home/daohang_logo.png" alt="">
				</div>
				<el-breadcrumb separator=">">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: path , query:query}">{{father_name}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{newsname}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="all-introduce">
				<!-- 视频播放 -->
			<!-- 	<div class="video" v-show="showvideo">
					<YSTeachVideo :videoUrl="videoUrl" :shownum='shownum'></YSTeachVideo>
					<div class="title">{{newsname}}</div>
					<div class="time">{{details_info.create_time}}</div>
				</div> -->
				<!-- 轮播图 v-show="!showvideo"-->
				<div>
					<!-- <div class="all-swiper">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<swiper class="swiper" :options="swiperOption" v-if="ImgArr.length > 0">
							<swiper-slide v-for="item in ImgArr" :key="item.id">
								<img :src="item.uri" alt="">
							</swiper-slide>
						</swiper>
						<div class="swiper-button-next" slot="button-next"></div>
					</div> -->
					<div class="title newclass">{{newsname}}</div>
					<div class="time newclass">{{details_info.create_time}}</div>
          <div class="all-photo" v-html="details_info.goods_img"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import YSTeachVideo from '@/components/ys-teach-video/index.vue'
	import BannerOther from '@/components/banner-other/index.vue'
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	import {
		getanlidetails
	} from "@/api/joggle.js"
	export default {
		name:'Case-details',
		components: {
			BannerOther,
			swiper,
			swiperSlide,
			YSTeachVideo,
		},
		data() {
			return {
				img_url: '',
				// chinese_name: '',
				english_name: '',
				father_name:'', // 上一页名称
				newsname:'', //新闻名称
				path:'/collection-cases' ,// 上页地址
				query:{},// 返回上一页传参
				showvideo:false, // 显示视频还是轮播，轮播为空就显示视频
				swiperOption: {
					loop: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
				},
				id:'', //上级id
				ImgArr:[], //轮播图列表
				videoUrl:'', // 视频播放路径
				details_info:'', // 详情信息
				shownum:'',
				keyval:''
			}
		},
		mounted() {
			var that = this

		},
		activated() {
			var that = this
			// that.videoUrl = that.$route.query.url;
			// that.shownum = that.$route.query.shownum;
			that.keyval = that.$route.query.top_keyword;
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.anli_image;
			}
			that.newsname = that.$route.query.name;
			that.father_name = that.$route.query.f_pagename;
			// 修改浏览器名称
			document.title = that.newsname + '-'+ that.father_name + '-MG影像';
			that.english_name = that.$route.query.f_englishname;
			localStorage.setItem('operate','非首次');
			if(that.father_name == '案例锦集'){
				that.query = {
					typename:'微电影',
					siderIndex:3,
					top_keyword:that.keyval
				}
			}
			if(that.father_name == '微电影' ){
				that.query = {
					typename:'微电影',
					siderIndex:4,
					top_keyword:that.keyval
				}
			}
			if(that.father_name == '婚礼旅拍' ){
				that.query = {
					typename:'婚礼旅拍',
					siderIndex:5,
					top_keyword:that.keyval
				}
			}
			if(that.father_name == '商业拍摄' ){
				that.query = {
					typename:'商业拍摄',
					siderIndex:6,
					top_keyword:that.keyval
				}
			}
			if(that.father_name == '企业宣传片' ){
				that.query = {
					typename:'企业宣传片',
					siderIndex:7,
					top_keyword:that.keyval
				}
			}
			if(that.father_name == '纪录片' ){
				that.query = {
					typename:'纪录片',
					siderIndex:8,
					top_keyword:that.keyval
				}
			}
			if(that.father_name == '政府宣传片' ){
				that.query = {
					typename:'政府宣传片',
					siderIndex:9,
					top_keyword:that.keyval
				}
			}
			that.id = that.$route.query.id;
			// 获取详情
			that.getListDetails(that.id);
		},

		methods:{
			// 获取详情
			getListDetails(id){
				var that = this
				var obj = {
					id:id
				}
				getanlidetails(obj).then(res => {
					if(res.code == 1){
						// that.ImgArr = res.data.img;
						// that.videoUrl = res.data.video;

						that.details_info = res.data;
						// if(that.ImgArr.length == 0){
						// 	that.showvideo = true;
						// }else{
						// 	that.showvideo = false;
						// }
					}else{
						that.$message.error(res.msg);
					}
				})
			}
		},
		// 离开页面事件
		beforeDestroy() {
			var that = this
		},
	}
</script>

<style lang="scss">
	@import '../../assets/css/case-details.scss';
	@import '../../assets/css/public.scss';
</style>
